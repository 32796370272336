import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AvatarComponent as AvatarDesignB } from "./avatar/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar.component";
import { AvatarInitComponent as AvatarInitDesignB } from "./avatar-init/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-init.component";
import { AvatarConversationBoardComponent as ChatDesignB } from "./avatar-conversation-board/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-conversation-board.component";
import { ContentModalComponent as ContentModalDesignB } from "./content-modal/design/4e9761a1-c136-4125-8706-d5de851a2e6f/content-modal.component";
import { DragDropComponent as DragDropDesignB } from "./avatar-drag-drop/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-drag-drop.component";
import { FeedbackComponent as FeedbackDesignB } from "./avatar-feedback/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-feedback.component";
import { LandingPageComponent as LandingPageDesignB } from "./landing-page/design/4e9761a1-c136-4125-8706-d5de851a2e6f/landing-page.component";
import { LanguagesComponent as LanguagesDesignB } from "./languages/design/4e9761a1-c136-4125-8706-d5de851a2e6f/languages.component";
import { AvatarControlsComponent as NavbarDesignB } from "./avatar-controls/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-controls.component";
import { ShortcutsComponent as ShortcutDesignB } from "./avatar-shortcuts/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-shortcuts.component";
import { AvatarHeadingComponent as TopSectionDesignB } from "./avatar-heading/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-heading.component";
import { AvatarVideoComponent as AvatarVideoDesignB } from "./avatar-video/design/4e9761a1-c136-4125-8706-d5de851a2e6f/avatar-video.component";
import { MessageComponent as MessageDesignB } from "./message/design/4e9761a1-c136-4125-8706-d5de851a2e6f/message.component";

import { ThumbDownSvgComponent as ThumbDownSvgDesignB } from "./message/svg/thumbs-down-b";
import { ThumbUpSvgComponent as ThumbUpSvgDesignB } from "./message/svg/thumbs-up-b";
import { AttachementSvgComponent } from './avatar-controls/svg/attachment';
import { MicrophoneSvgComponent } from './avatar-controls/svg/microphone';
import { CloseSvgComponent } from './avatar-feedback/design/svg/close';
import { SafePipe } from 'app/pipes/safe.pipe';

@NgModule({
    declarations: [
        AvatarDesignB,
        AvatarVideoDesignB,
        AvatarInitDesignB,
        ChatDesignB,
        ContentModalDesignB,
        DragDropDesignB,
        FeedbackDesignB,
        LandingPageDesignB,
        LanguagesDesignB,
        NavbarDesignB,
        ShortcutDesignB,
        TopSectionDesignB,
        MessageDesignB,
        ThumbUpSvgDesignB,
        ThumbDownSvgDesignB,
        CloseSvgComponent,
        AttachementSvgComponent,
        MicrophoneSvgComponent,
        SafePipe    
    ],
    exports: [
        AvatarDesignB,
        AvatarVideoDesignB,
        AvatarInitDesignB,
        ChatDesignB,
        ContentModalDesignB,
        DragDropDesignB,
        FeedbackDesignB,
        LandingPageDesignB,
        LanguagesDesignB,
        NavbarDesignB,
        ShortcutDesignB,
        TopSectionDesignB,
        MessageDesignB,
        ThumbUpSvgDesignB,
        ThumbDownSvgDesignB,
        CloseSvgComponent,
        AttachementSvgComponent,
        MicrophoneSvgComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgOptimizedImage
    ]
})
export class ComponentModule {}
